import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  postWarehouseList(call, obj) {
    return this.getData("/cp/warehouse_list", call, obj);
  },
  validateWarehouse(call, ware, userId) {
    return this.getData(`/cp/warehouse_validate?ware=${ware}&userid=${userId}`, call);
  },
  getWarehouseLocation(call, selectedProject, userId) {
    return this.getData(`/cp/warehouse_company_info?Pass=${2}&ProjKey=${selectedProject}&UserId=${userId}&CompanyId=${0}`, call);
  },
  getWarehouseOwner(call, selectedProject, userId) {
    return this.getData(`/cp/warehouse_company_info?Pass=${1}&ProjKey=${selectedProject}&UserId=${userId}&CompanyId=${0}`, call);
  },
  getCompanyAddress(call, userId, selectedWarehouseOwner) {
    return this.getDataNoLoader(`/cp/warehouse_company_info?Pass=${3}&ProjKey=${0}&UserId=${userId}&CompanyId=${selectedWarehouseOwner}`, call);
  },
  getWarehouseInfoList(call, pass, userId) {
    return this.getData(`/cp/warehouse_info_list?Pass=${pass}&UserId=${userId}`, call);
  },
  postWarehouseCreate(call, obj, showMessage) {
    return this.getData("/cp/warehouse_create", call, obj, showMessage);
  },
  postWarehouseUpdate(call, obj, showMessage) {
    return this.getData("/cp/warehouse_update", call, obj, showMessage);
  },
  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    //When Response is having body as null
    if (res == undefined || res == null) {
      LoaderDialog.visible = false;
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      return JSON.parse('{"message":"NA"}');
    }
    if (res.data.body.message != undefined || res.data.body.message != null) {
      //When message in response is coming as string
      if (typeof res.data.body.message == "string") {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.body.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return res.data.body.message;
        } else {
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return res.data.body.message;
        }
      }
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res.data.body.message == null) {
      LoaderDialog.visible = false;
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      return JSON.parse('{"message":"NA"}');
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
  async getDataNoLoader(url, call, obj, showMessage) {
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
      });
    }
    //When Response is having body as null
    if (res == undefined || res == null) {
      return JSON.parse('{"message":"NA"}');
    }
    if (res.data.body.message != undefined || res.data.body.message != null) {
      //When message in response is coming as string
      if (typeof res.data.body.message == "string") {
        return res.data.body.message;
      }
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        return JSON.parse(res?.data?.body?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res.data.body.message == null) {
      return JSON.parse('{"message":"NA"}');
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.message;
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
};
