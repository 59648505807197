import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import warehouseService from "../js/warehouse-service";
import commonService from "../../common/js/common-api-service";
export default {
  name: "warehouseComp",
  props: {
    name: String,
    compCalled: Boolean,
    warehouseTypeList: Array,
    projectId: String,
    warehouseName: String,
    projKey: String,
    buKey: String,
    warehouseId: String,
    warehousetypeKey: String,
    active: Boolean,
    Segregated: Boolean,
    warehouseOwner: String,
    warehouseLocation: String,
    actionList: Array,
    switchList: Array,
  },
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    required: [(v) => !!v || "Field is required"],
    max20Rule: [(v) => (v || "").length <= 20 || "Max Length of 20 character", (v) => !!v || "Field is required"],
    selectedProject: "",
    warehouse: "",
    projectData: [],
    selectedWarehouse: "",
    selectedBusiness: "",
    showUpdate:false,
    warehouseActive: true,
    allowAsSub: false,
    deleteWarehouseDialog: false,
    selectedWarehouseOwner: "",
    selectedWareLocation: "",
    segregated: false,
    FGI: false,
    TBE: false,
    scrap: false,
    edit: false,
    WIP: false,
    MRB: false,
    validate: false,
    businessList: [],
    businessData: [],
    warehouseOwnerData: [],
    po_address1: "",
    po_address2: "",
    po_city: "",
    po_state: "",
    po_postal: "",
    po_country: "",
    po_contactName: "",
    po_phone1: "",
    po_phone2: "",
    po_cell: "",
    po_fax: "",
    po_email: "",
    warehouseInfoList: [],
    wl_address1: "",
    wl_address2: "",
    wl_city: "",
    wl_state: "",
    wl_postal: "",
    wl_country: "",
    wl_contactName: "",
    wl_phone1: "",
    wl_phone2: "",
    wl_cell: "",
    wl_fax: "",
    wl_email: "",
    wareLocData: [],
    allowSub_Enabled: false,
    FGI_Enabled: false,
    TBE_Enabled: false,
    MRB_Enabled: false,
    WIP_Enabled: false,
    SCP_Enabled: false,
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    wareId: 0,
    warehouseList: [],
    emptyWarehouseLocation: "",
    emptyWarehouseOwner: "",
  }),
  created() {
    this.getBusinessList();
    this.getProjectList();
    this.getWarehouseInfoList();
    //For edit
    if (this.name != "newWarehouse") {
      this.getWarehouseList();
      this.warehouseActive = this.switchList.warehouseActive;
      this.allowAsSub = this.switchList.allowAsSub;
      this.segregated = this.switchList.segregated;
      this.FGI = this.switchList.FGI;
      this.TBE = this.switchList.TBE;
      this.WIP = this.switchList.WIP;
      this.MRB = this.switchList.MRB;
      this.scrap = this.switchList.scrap;
      this.wareId = this.warehouseId;
      this.selectedProject = this.projKey;
      this.editAddressDetails();
    }
  },
  mounted() {},
  methods: {
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList != null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter != undefined && filter != null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Get Warehouse details
    async getWarehouseList() {
      this.warehouseList = [];
      let searchObject = {
        Ware: this.warehouseName,
        ProjectCode: this.projKey,
        Bu_Code: this.buKey,
        strActive: this.active ? "1" : "0",
        WareType_key: this.warehousetypeKey,
        UserId: parseInt(this.userId),
      };
      let responseData = await warehouseService.postWarehouseList("post", searchObject);
      if (responseData != null && responseData != undefined) {
        this.warehouseList = JSON.parse(responseData);
      }
      if (this.warehouseList.length != 0) {
        if (this.warehouseList[0].createdate != "" && this.warehouseList[0].createdate != undefined) {
          this.createdDate = Utility.convertESTToLocal(this.warehouseList[0].createdate);
        }
        if (this.warehouseList[0].createdby != "" && this.warehouseList[0].createdby != undefined) {
          this.createdBy = this.warehouseList[0].createdby;
        }
        if (this.warehouseList[0].udate != "" && this.warehouseList[0].udate != undefined) {
          this.updatedDate = Utility.convertESTToLocal(this.warehouseList[0].udate);
        }
        if (this.warehouseList[0].uby != "" && this.warehouseList[0].uby != undefined) {
          this.updatedBy = this.warehouseList[0].uby;
        }
      }
    },
    //onClick cancel
    onClickCancel() {
      this.compCalled = false;
      this.$emit("hide");
    },
    //Get Project List
    async getProjectList() {
      let response = await commonService.getProject("get", this.userId);
      if (response != null && response != undefined) {
        this.projectData = response.ProjectInfo;
      }
    },
    //Get Business DropDown
    async getBusinessList() {
      let response = await commonService.getBusiness("get", this.userId);
      if (response != null && response != undefined) {
        this.businessData = response;
        this.businessList = this.businessData;
      }
    },
    //Check WareHouse is valid or not
    async warehouseCheck(warehouse) {
      if (this.$refs.warehouseCheckForm.validate()) {
        let response = await warehouseService.validateWarehouse("get", warehouse, this.userId);
        if (response.message != "NA") {
          this.validate = true;
        }
      }
    },
    //To get the business List with respect to project Selected
    onClickProject() {
      this.businessData = this.businessList.filter((element) => element.ProjKey == this.selectedProject);
      //To get the warehouse owner and location list
      this.getWarehouseLocation();
      this.getWarehouseOwner();
    },
    //To get Warehouse Location
    async getWarehouseLocation() {
      let response = await warehouseService.getWarehouseLocation("get", this.selectedProject, this.userId);
      if (response != null && response != undefined) {
        this.wareLocData = JSON.parse(response).CompanyInfo;
      }
    },
    async editAddressDetails() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      //Location Details for Edit Warehouse
      let locationData = await warehouseService.getWarehouseLocation("get", this.selectedProject, this.userId);
      if (locationData != null && locationData != undefined) {
        this.wareLocData = JSON.parse(locationData).CompanyInfo;
      }
      this.emptyWarehouseLocation = "";
      this.emptyWarehouseLocation = this.wareLocData.find((element) => element.comp_id == this.warehouseLocation);
      if (this.emptyWarehouseLocation != null || this.emptyWarehouseLocation != undefined) {
        let companyDet = await warehouseService.getCompanyAddress("get", this.userId, this.warehouseLocation);
        let dataLoc = JSON.parse(companyDet).CompanyInfo;
        if (dataLoc != null && dataLoc != undefined) {
          this.wl_address1 = dataLoc[0].address1;
          this.wl_address2 = dataLoc[0].address2;
          this.wl_city = dataLoc[0].city;
          this.wl_state = dataLoc[0].state;
          this.wl_postal = dataLoc[0].Zip;
          this.wl_country = dataLoc[0].country;
          this.wl_contactName = dataLoc[0].ContName;
          this.wl_phone1 = dataLoc[0].phone1;
          this.wl_phone2 = dataLoc[0].phone2;
          this.wl_cell = dataLoc[0].cell;
          this.wl_fax = dataLoc[0].fax;
          this.wl_email = dataLoc[0].email;
        }
      } else if (this.emptyWarehouseLocation == undefined) {
        this.wl_address1 = "";
        this.wl_address2 = "";
        this.wl_city = "";
        this.wl_state = "";
        this.wl_postal = "";
        this.wl_country = "";
        this.wl_contactName = "";
        this.wl_phone1 = "";
        this.wl_phone2 = "";
        this.wl_cell = "";
        this.wl_fax = "";
        this.wl_email = "";
      }
      //Owner Details for the Edit Warehouse
      let ownerData = await warehouseService.getWarehouseOwner("get", this.selectedProject, this.userId);
      if (ownerData != null && ownerData != undefined) {
        this.warehouseOwnerData = JSON.parse(ownerData).CompanyInfo;
      }
      this.emptyWarehouseOwner = "";
      this.emptyWarehouseOwner = this.warehouseOwnerData.find((element) => element.comp_id == this.warehouseOwner);
      if (this.emptyWarehouseOwner != null || this.emptyWarehouseOwner != undefined) {
        let companyDet2 = await warehouseService.getCompanyAddress("get", this.userId, this.warehouseOwner);
        let dataOwn = JSON.parse(companyDet2).CompanyInfo;
        if (dataOwn != null && dataOwn != undefined) {
          this.po_address1 = dataOwn[0].address1;
          this.po_address2 = dataOwn[0].address2;
          this.po_city = dataOwn[0].city;
          this.po_state = dataOwn[0].state;
          this.po_postal = dataOwn[0].Zip;
          this.po_country = dataOwn[0].country;
          this.po_contactName = dataOwn[0].ContName;
          this.po_phone1 = dataOwn[0].phone1;
          this.po_phone2 = dataOwn[0].phone2;
          this.po_cell = dataOwn[0].cell;
          this.po_fax = dataOwn[0].fax;
          this.po_email = dataOwn[0].email;
        }
      } else if (this.emptyWarehouseOwner == undefined) {
        this.po_address1 = "";
        this.po_address2 = "";
        this.po_city = "";
        this.po_state = "";
        this.po_postal = "";
        this.po_country = "";
        this.po_contactName = "";
        this.po_phone1 = "";
        this.po_phone2 = "";
        this.po_cell = "";
        this.po_fax = "";
        this.po_email = "";
      }
      LoaderDialog.visible = false;
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // TO get Warehouse Owner
    async getWarehouseOwner() {
      let response = await warehouseService.getWarehouseOwner("get", this.selectedProject, this.userId);
      if (response != null && response != undefined) {
        this.warehouseOwnerData = JSON.parse(response).CompanyInfo;
      }
    },
    // To get company Adress
    async getCompanyAdress(selected, selectedWarehouseOwner) {
      let response = await warehouseService.getCompanyAddress("get", this.userId, selectedWarehouseOwner);
      let data = JSON.parse(response).CompanyInfo;
      if (data != null && data != undefined) {
        if (selected == "Owner" && (selectedWarehouseOwner != null || selectedWarehouseOwner != undefined)) {
          this.po_address1 = data[0].address1;
          this.po_address2 = data[0].address2;
          this.po_city = data[0].city;
          this.po_state = data[0].state;
          this.po_postal = data[0].Zip;
          this.po_country = data[0].country;
          this.po_contactName = data[0].ContName;
          this.po_phone1 = data[0].phone1;
          this.po_phone2 = data[0].phone2;
          this.po_cell = data[0].cell;
          this.po_fax = data[0].fax;
          this.po_email = data[0].email;
        } else {
          this.wl_address1 = data[0].address1;
          this.wl_address2 = data[0].address2;
          this.wl_city = data[0].city;
          this.wl_state = data[0].state;
          this.wl_postal = data[0].Zip;
          this.wl_country = data[0].country;
          this.wl_contactName = data[0].ContName;
          this.wl_phone1 = data[0].phone1;
          this.wl_phone2 = data[0].phone2;
          this.wl_cell = data[0].cell;
          this.wl_fax = data[0].fax;
          this.wl_email = data[0].email;
        }
      } else {
        this.companyList = [];
        this.exportDisable = true;
        this.totalRecords = 0;
        this.pageCount = 0;
      }
    },
    //To change the warehouse owner and location based on project selected
    onProjectOwnerSelect(selectedWarehouseOwner) {
      this.getCompanyAdress("Owner", selectedWarehouseOwner);
    },
    //To Get Details Of Warehouse Location
    onWareLocSelect(selectedWareLocation) {
      this.getCompanyAdress("Location", selectedWareLocation);
    },
    //Fill the adress for warehouse location
    //Get info warehouse List
    async getWarehouseInfoList() {
      let pass = 0;
      if (this.name != "newWarehouse") pass = 3;
      else pass = 2;
      let response = await warehouseService.getWarehouseInfoList("get", pass, this.userId);
      if (response != null && response != undefined) {
        this.warehouseInfoList = JSON.parse(response);
        if (this.name != "newWarehouse") {
          this.assignValues();
        }
      }
    },
    // change on based selection
    onWarehouseChange(selectedWarehouse) {
      let displayString = this.warehouseTypeList.filter((element) => element.list_id == selectedWarehouse);
      let name = displayString[0].displaystring;
      let values = this.warehouseInfoList.filter((element) => element.Name == name);
      this.allowSub_Enabled = values[0].AllowSub_Enabled;
      this.FGI_Enabled = values[0].FGI_Enabled;
      this.TBE_Enabled = values[0].TBE_Enabled;
      this.MRB_Enabled = values[0].MRB_Enabled;
      this.WIP_Enabled = values[0].WIP_Enabled;
      this.SCP_Enabled = values[0].SCP_Enabled;
    },
    //Create WareHouse
    async onClickAdd() {
      if (this.$refs.warehouseAddForm.validate()) {
        let addObject = {
          BuJson: JSON.stringify({
            ware_key: 0,
            loca_key: this.selectedWareLocation,
            active: this.warehouseActive ? 1 : 0,
            segregated: this.segregated ? 1 : 0,
            allow_as_sub: this.allowAsSub ? 1 : 0,
            owner_key: this.selectedWarehouseOwner,
            fgi: this.FGI ? 1 : 0,
            tbe: this.TBE ? 1 : 0,
            mrb: this.MRB ? 1 : 0,
            scp: this.scarp ? 1 : 0,
            wip: this.WIP ? 1 : 0,
            fsl: 0,
            ware: this.warehouse,
            user_Key: parseInt(this.userId),
            bu_key: this.selectedBusiness,
            proj_key: this.selectedProject,
            wtype_key: this.selectedWarehouse,
          }),
        };
        let showMessage = true;
        let response = await warehouseService.postWarehouseCreate("post", addObject, showMessage);
        if (response != null && response != undefined) {
          this.compCalled = false;
          this.$emit("wareUpdated");
          this.deleteWarehouseDialog = false;
        }
      }
    },
    //Prepopulating the value
    assignValues() {
      this.selectedProject = this.projKey;
      this.selectedBusiness = this.buKey;
      this.warehouse = this.warehouseName;
      this.selectedWarehouse = this.warehousetypeKey;
      this.warehouseActive = this.active;
      this.segregated = this.Segregated;
      this.selectedWarehouseOwner = this.warehouseOwner;
      this.selectedWareLocation = this.warehouseLocation;
      this.onWarehouseChange(this.selectedWarehouse);
    },
    //Update API call
    async onClickUpdate() {
      let addObject = {
        BuJson: JSON.stringify({
          ware_key: this.wareId,
          loca_key: this.selectedWareLocation,
          active: this.warehouseActive ? 1 : 0,
          segregated: this.segregated ? 1 : 0,
          allow_as_sub: this.allowAsSub ? 1 : 0,
          owner_key: this.selectedWarehouseOwner,
          fgi: this.FGI ? 1 : 0,
          tbe: this.TBE ? 1 : 0,
          mrb: this.MRB ? 1 : 0,
          scp: this.scarp ? 1 : 0,
          wip: this.WIP ? 1 : 0,
          fsl: 0,
          ware: this.warehouse,
          user_Key: parseInt(this.userId),
          bu_key: this.selectedBusiness,
          proj_key: this.selectedProject,
          wtype_key: this.selectedWarehouse,
        }),
      };
      let showMessage = true;
      let response = await warehouseService.postWarehouseUpdate("post", addObject, showMessage);
      if (response != null && response != undefined) {
        this.compCalled = false;
        this.$emit("wareUpdated");
        this.deleteWarehouseDialog = false;
      }
    },
    //Close the dialog
    closeDialog() {
      this.compCalled = false;
      this.$emit("hide");
      this.deleteWarehouseDialog = false;
    },
  },
};
