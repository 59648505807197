<template>
  <div>
    <!-- S:Bread crumbs -->
    <v-row>
      <v-col sm="12" md="12" lg="12" class="pa-10 pb-5">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :subPage="subPage" :backSlash="backSlash" :pageUrl="pageUrl"
            :showAdd="showAdd" @addFav="addFav()" :refresh="refresh" :resetFunction="resetFunction" />
          <!--  Bread Crumb for mobile -->
          <breadcrumbComp class="hidden-md-and-up" :subPage="subPage" :pageUrl="pageUrl" :showAdd="showAdd" @addFav="addFav()" :refresh="refresh"
            :resetFunction="resetFunction" />
          <!-- E:Breadcrumbs -->
          <!-- Search Card -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="pa-4">
              <v-card outlined color="transparent" class="pa-0 ma-0 containerbox pb-5">
                <v-card-text class="pb-0 pt-0">
                  <v-form ref="WarehouseForm" v-model="isFormValid" class="pa-2 pr-5" lazy-validation>
                    <v-row class="pt-5">
                      <!-- For Company ID -->
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-text-field color="primarytext" v-model="warehouse" label="Warehouse" outlined clearable dense class="ma-0"
                          @keydown.enter="getWarehouseList" :rules="max20Rule" persistent-placeholder>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-autocomplete color="primarytext" attach outlined v-model="projectCode" :items="projectData" item-text="Project"
                          item-value="ProjKey" label="Project" dense persistent-placeholder @change="getProjBusinessList()" :disabled="!projSelected">
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-autocomplete color="primarytext" attach outlined v-model="businessCode" :items="businessData" item-text="Business"
                          item-value="Bu_id" label="Business" dense persistent-placeholder :disabled="projSelected">
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-select v-model="selectedWarehouse" item-text="displaystring" item-value="list_id" dense :items="warehouseTypeList"
                          label="Warehouse Type" outlined persistent-placeholder></v-select>
                      </v-col>
                      <!-- For Toggle between Active and inactive -->
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-switch class="ma-2 d-inline-block" v-model="active" :label="active ? 'Active' : 'Inactive'" color="primarytext"
                          hide-details inset></v-switch>
                      </v-col>
                      <!--  Serarch Button and cancel -->
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-row>
                          <v-col cols="6" sm="3" md="3" lg="12">
                            <v-btn color="primarytext" outlined class="mx-auto btn-style" elevation="1" @click="getWarehouseList()"
                              :disabled="!isFormValid">
                              <v-icon dark left> mdi-magnify </v-icon> Search Warehouse
                            </v-btn> &nbsp;&nbsp;
                            <v-btn color="secondary" outlined dense class="mx-auto btn-style" elevation="1" @click="onSearchCancel()">
                              <v-icon dark left> mdi-magnify-close </v-icon>Clear Search
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Table -->
    <v-row class="pa-7 pt-0">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card outlined class="containerbox">
          <v-card-text class="pb-0 pt-0">
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="8" class="px-2 pt-10 pl-8">
                <span color="primarytext">
                  No.of Records: {{ totalRecords }}
                </span>
              </v-col>
              <v-col cols="12" sm="8" md="8" lg="4" class="px-1 ma-0 pt-8 pr-8">
                <!-- Button to add user -->
                <v-btn class="ma-2 btn-style float-right success" elevation="1" outlined color="cWhite" @click="newAddClicked()"
                  v-if="actionSetter('AddWarehouse')">
                  <v-icon dark left> mdi-plus </v-icon>New Warehouse
                </v-btn>
                <!-- Company add Comp -->
                <export-excel class="btn btn-default" :data="warehouseList" :fields="json_fields" worksheet="Sheet1" :name="excelName"
                  v-if="actionSetter('ExportWarehouse')">
                  <!-- Button to export -->
                  <v-btn :disabled="exportDisable" class="ma-2 btn-style float-right" elevation="1" outlined color="secondary" @click="fileName()">
                    <v-icon dark left> mdi-file-export </v-icon>Export
                  </v-btn>
                </export-excel>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="pa-0 pl-8 pr-8 pb-5">
                <v-data-table :headers="headers" :items="warehouseList" item-key="ware_id">
                  <!-- To show all chips -->
                  <template v-slot:item.active="{ item }">
                    <span class="active" v-if="item.active == 'Y'">Active</span>
                    <span class="inactive" v-else>Inactive</span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="warehouseDetails(item)">
                      mdi-eye
                    </v-icon>
                  </template>

                  <template v-slot:item.ware="{ item }">
                    <a @click="warehouseDetails(item)" v-bind:class="($vuetify.theme.dark) ? 'anchorDark' : 'anchorlight'">
                      {{ item.ware }}
                    </a>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--  Warehouse comp called -->
    <warehouseComp v-if="compCalled" :name="name" :compCalled="compCalled" @hide="close()" @wareUpdated="updateWarehouse()" :projectId="projectId"
      :warehouseTypeList="warehouseTypeList" :warehouseName="warehouseName" :projKey="projKey" :buKey="buKey" :warehouseId="warehouseId"
      :warehousetypeKey="warehousetypeKey" :active="warehouseActive" :Segregated="segregated" :warehouseOwner="warehouseOwner"
      :warehouseLocation="warehouseLocation" :actionList="actionList" :switchList="switchList" />
  </div>
</template>

<script>
import Vue from 'vue';
import excel from 'vue-excel-export';
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import EncryptUtility from '../utility/js/encrypt-utility';
import Utility from '../../shared/utility';
import warehouseComp from '../company/app-warehouse-comp.vue';
import AddFavourite from "../utility/js/addFav";
import commonAPIService from "../common/js/common-api-service";
Vue.use(excel);
export default {
  data: () => ({
    name: 'newWarehouse',
    isFormValid: false,
    max20Rule: [(v) => (v || '').length <= 20 || 'Max Length of 20 character'],
    warehouse: '',
    businessCode: '',
    active: true,
    selectedWarehouse: '',
    projectName: '',
    warehouseTypeList: [],
    backSlash: true,
    mainPage: '',
    subPage: '',
    pageUrl: '',
    excelName: '',
    exportDisable: true,
    projectCode: "",
    projectId: '',
    warehouseList: [],
    compCalled: false,
    userId: EncryptUtility.localStorageDecrypt('userID'),
    warehouseName: '',
    projKey: 0,
    buKey: 0,
    warehouseId: 0,
    favouriteList: [],
    showAdd: false,
    warehousetypeKey: 0,
    warehouseActive: true,
    segregated: false,
    warehouseOwner: 0,
    warehouseLocation: 0,
    totalRecords: 0,
    refresh: true,
    json_fields: {
      Warehouse: 'ware',
      Project_Code: 'proj_code',
      Business_Code: 'bu_code',
      Warhouse_Type: 'warehouseType',
      Segregated: 'segregated',
      FGI: 'fgi',
      TBE: 'tbe',
      MRB: 'mrb',
      Scrap: 'scp',
      WIP: 'wip',
      active: 'active',
    },
    json_meta: [
      [
        {
          key: 'charset',
          value: 'utf-8',
        },
      ],
    ],
    headers: [
      {
        text: 'Warehouses',
        align: 'start',
        value: 'ware',
        class: 'primary customwhite--text',
      },
      {
        text: 'Project Code',
        value: 'proj_code',
        class: 'primary customwhite--text',
      },
      {
        text: 'Business Code',
        value: 'bu_code',
        class: 'primary customwhite--text',
      },
      {
        text: 'Warehouse Type',
        value: 'displaystring',
        class: 'primary customwhite--text',
      },

      {
        text: 'Segregated',
        value: 'segregated',
        class: 'primary customwhite--text',
      },
      { text: 'FGI', value: 'fgi', class: 'primary customwhite--text' },
      { text: 'TBE', value: 'tbe', class: 'primary customwhite--text' },
      { text: 'MRB', value: 'mrb', class: 'primary customwhite--text' },
      { text: 'Scrap', value: 'scp', class: 'primary customwhite--text' },
      { text: 'WIP', value: 'wip', class: 'primary customwhite--text' },
      { text: 'Status', value: 'active', class: 'primary customwhite--text' },
      {
        text: 'Actions',
        value: 'actions',
        class: 'primary customwhite--text',
        sortable: false,
      },
    ],
    actionList: [],
    route: '',
    switchList: [],
    projectData: [],
    projSelected: true,
    businessData: [],
  }),
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route)
    this.actionList = data.actionList
    this.mainPage = data.mainPage
    this.favouriteList = data.favouriteList
    this.pageUrl = data.pageUrl
    this.subPage = data.subPage
    this.showAdd = data.showAdd
  },
  mounted() {
    this.getWarehouseTypeList();
    this.getProjectList();
  },
  methods: {
    //Reset function
    resetFunction() {
      this.warehouse = "";
      this.projectCode = "";
      this.projectData = [];
      this.businessCode = "";
      this.businessData = [];
      this.selectedWarehouse = "";
      this.warehouseTypeList = [];
      this.active = true;
      this.compCalled = false;
      this.warehouseList = [];
      this.projSelected = true;
      this.$refs.WarehouseForm.resetValidation();
      this.getWarehouseTypeList();
      this.getProjectList();
    },
    //Get Project List
    async getProjectList() {
      this.projectData = [];
      let data = await commonAPIService.getProject("get", this.userId);
      if (data.message !== "NA") {
        this.projectData = data.ProjectInfo;
      }
    },
    //Get Business based on the project selection
    getProjBusinessList() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let businessObj = {
        UserId: parseInt(this.userId),
        ProjKey: parseInt(this.projectCode),
        BuKey: 0,
        IncludeEmptyBU: 0,
        WithinSameLocation: 0,
        loca_key: 0
      };
      this.axios
        .post('/co/business_getbyId', businessObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.businessData = responseData;
            this.projSelected = false;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          this.warehouseList = [];
          this.exportDisable = true;
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //To get the Details of warehouse and pass it to component
    warehouseDetails(item) {
      this.compCalled = true;
      this.name = 'editWarehouse';
      //Do not Delete it
      this.warehouseName = item.ware;
      this.projKey = item.proj_key;
      this.buKey = item.bu_key;
      this.warehouseId = item.ware_id;
      this.warehousetypeKey = item.wtype_key;
      this.warehouseActive = item.active == 'Y' ? true : false;
      this.segregated = item.segregated == 'Y' ? true : false;
      this.warehouseOwner = item.owner_key;
      this.warehouseLocation = item.loca_key;
      this.switchList = {
        FGI: item.fgi == 'Y' ? true : false,
        warehouseActive: item.active == 'Y' ? true : false,
        allowAsSub: item.cf_allow,
        segregated: item.segregated == 'Y' ? true : false,
        TBE: item.tbe == 'Y' ? true : false,
        WIP: item.wip == 'Y' ? true : false,
        MRB: item.mrb == 'Y' ? true : false,
        scrap: item.scp == 'Y' ? true : false
      };
    },
    //To warehouse the warehouse List
    getWarehouseList() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchObject = {
        Ware: this.warehouse,
        ProjectCode: this.projectCode,
        Bu_Code: this.businessCode,
        strActive: this.active ? '1' : '0',
        WareType_key: this.selectedWarehouse,
        UserId: this.userId,
      };
      this.totalRecords = 0;
      this.axios
        .post('/cp/warehouse_list', searchObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.warehouseList = responseData;
            this.totalRecords = responseData.length;
            this.exportDisable = false;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            this.warehouseList = [];
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          this.warehouseList = [];
          this.exportDisable = true;
          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //To get the list of warehouse Type
    getWarehouseTypeList() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.axios
        .get(`cp/warehouse_info_list?Pass=1&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.warehouseTypeList = responseData;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.editMenuDialog = false;
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);

          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //On click of cancel
    onSearchCancel() {
      this.warehouse = '';
      this.businessCode = '';
      this.projectCode = "";
      this.selectedWarehouse = '';
      this.active = true;
      this.projSelected = true;
      this.exportDisable = true;
      this.warehouseList = [];
      this.totalRecords = 0;
    },
    //To close the componets
    close() {
      this.name = '';
      this.compCalled = false;
    },
    //When a new warehouse is added or existing warehouse is updated
    updateWarehouse() {
      this.name = '';
      this.compCalled = false;
      this.getWarehouseList();
    },
    //File name
    fileName() {
      this.excelName =
        'WarehouseList_' +
        new Date()
          .toISOString()
          .replace('T', '_')
          .replace('Z', '')
          .replace('.', '')
          .replaceAll('-', '_') +
        '.xls';
    },
    //On click new add
    newAddClicked() {
      this.compCalled = true;
      this.name = 'newWarehouse';
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action,
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route)
    },
    //To add or remove Favourite issue
    //Get Page Action
    getPageAction(url, userId) {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      this.axios
        .get(`/ap/user_page_actions?user_id=${userId}&page_url=${url}`)
        .then((response) => {
          if (response.status == 200) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.actionList = responsedata.Action;
            this.mainPage = responsedata.ParentName;
            this.subPage = responsedata.PageName;
            this.pageUrl = responsedata.PageUrl;
            this.favouriteList = responsedata.Favourites;
            this.showAdd = false;
            if (this.favouriteList.length > 0) {
              let exists = this.favouriteList.some(element => element.Url == this.route);
              if (exists) {
                this.showAdd = true;
              }
            }
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            var Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          var Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
    warehouseComp,
  },
};
</script>

<style scoped>
.active {
  background-color: #2ed8b6;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.inactive {
  background-color: #ff5370;
  border-radius: 5px;
  padding: 5px;
  color: white;
}
</style>